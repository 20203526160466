<template>
  <div class="maintecie text-center">
    <div class="maintence-box">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <img src="../../assets/img/dlogo.svg">
            <h2>Сайт в режиме разработки!</h2>
            <h4>
              Дорогие друзья! Мы готовим каталоги предзаказа на сезон 2025.
              <br>
              Совсем скоро сайт будет доступен для оформления заказа
            </h4>
            <a
              href="https://library.sflora.ru/"
              target="_blank"
            >
              <button
                class="green-btn"
                style="margin-bottom: 40px;"
              >справочник</button>
            </a>
          </div>
          <div class="col-lg-4 col-lg-offset-4">
            <br>
            <label>Пароль для администратора</label>
            <input
              type="password"
              placeholder="Пароль"
              v-model="pass"
            >
            <button
              class="green-btn"
              @click="enterSite"
            >
              Войти
            </button>
          </div>
          <div
            class="col-lg-12"
            v-for="(item, index) in files"
            :key="index"
          >
            <a
              :href="item.link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h4>
                <span
                  class="mdi"
                  :class="item.icon"
                />
                {{ item.name }}
              </h4>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pass: '',
      files: [
          {
            link: 'https://sflora.ru/wp-content/uploads/2024/10/assortiment_spb.pdf',
            name: 'Ассортимент СПБ.pdf',
            icon: 'mdi-file-document-outline'
          },
          {
            link: 'https://sflora.ru/wp-content/uploads/2024/10/assortiment_regiony.pdf',
            name: 'Ассортимент Регионы.pdf',
            icon: 'mdi-file-document-outline'
          },
          {
            link: 'https://sflora.ru/wp-content/uploads/2024/10/assortiment_spb.docx',
            name: 'Ассортимент СПБ.docx',
            icon: 'mdi-file-document-outline'
          },
          {
            link: 'https://sflora.ru/wp-content/uploads/2024/10/assortiment_regiony.docx',
            name: 'Ассортимент Регионы.docx',
            icon: 'mdi-file-document-outline'
          },
          {
            link: 'https://sflora.ru/wp-content/uploads/2024/10/regiony_assortiment.zip',
            name: 'Регионы ассортимент.zip',
            icon: 'mdi-folder-zip-outline'
          },
          {
            link: 'https://sflora.ru/wp-content/uploads/2024/10/spb_assortiment.zip',
            name: 'СПБ ассортимент.zip',
            icon: 'mdi-folder-zip-outline'
          },
        ]
    }
  },
  methods: {
    enterSite() {
      if (this.pass === 'sflora1337') {
        this.$emit('openSite')
      } else {
        alert('неверный пароль')
      }
    }
  }
}
</script>

<style scoped>
img {
  margin-bottom: 20px;
}

h2 {
  margin-bottom: 20px;
}

.maintecie {
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.maintence-box{
  height: 100%;
  overflow: auto;
  padding: 14px 0;
}

input {
  background-color: #f2f2f2;
  border-radius: 0px;
  border: none;
  color: #000;
  font-size: 16px;
  padding: 8px 10px;
  margin-bottom: 0;
  width: 100%;
  margin-right: 10px;
  margin-bottom: 25px;
  border: 1px solid #f2f2f2;
}


h4 span{
  font-size: 20px;
}
a h4{
  text-decoration: underline;
  font-size: 14px;
  margin: 12px 0;
}
a{
  transition: all .2s ease;
}
a:hover{
  opacity: .6;
}
</style>