<template>
  <div id="app">
    <SelectCatalog v-if="regionModal" />

    <ShowRules v-if="rules" />

    <transition
      name="slide"
      mode="out-in"
    >
      <div
        class="preloader"
        v-if="preload"
      >
        <img
          src="./assets/img/dlogo.svg"
          alt=""
        >
      </div>
    </transition>

    <router-link
      tag="div"
      to="/cart"
      class="fixed-cart-btn"
    >
      <span class="mdi mdi-cart" />
      <div class="cart-count">
        {{ cart.length }}
      </div>
    </router-link>

    <Header />
    <NavMain />
    <router-view />
    <Footer />

    <Maintecie
      v-if="devMode"
      @openSite="devMode = false"
    />
  </div>
</template>

<script>
import './assets/css/style.css'
import { DEV_MODE } from './config'
import Header from './components/ui/Header.vue'
import Footer from './components/ui/Footer.vue'
import Maintecie from './components/ui/Maintecie.vue'
import NavMain from './components/ui/NavMain.vue'
import SelectCatalog from './components/SelectCatalog.vue'
import ShowRules from './components/ShowRules.vue'
import { mapGetters } from 'vuex'

export default {
  components: { Header, Footer, NavMain, SelectCatalog, ShowRules, Maintecie },
  computed: {
    ...mapGetters({
      regionModal: "catalog/getRegionModal",
      cart: "cart/getCart",
      rules: "catalog/getRules"
    }),
    localhost(){
      return DEV_MODE
    }
  },
  data() {
    return {
      devMode: true,
      preload: true
    }
  },
  async created() {
    console.log("%cDeveloped in Webink.site", "padding: 8px 10px; margin: 10px 0; background-color: #095dff; color: #fff; font-weight:600; font-size: 13px;")
    console.log("%cBackend - @vadimpolh", "padding: 8px 10px; margin: 10px 0; background-color: #ECECEC; color: #333; font-weight:600; font-size: 13px;")
    console.log("%cFrontend - @famzayka", "padding: 8px 10px; margin: 10px 0; background-color: #333; color: #fff; font-weight:600; font-size: 13px;")

    this.$store.dispatch("catalog/loadFilterParams")

    await this.$store.dispatch("content/loadSimpleNews")
    await this.$store.dispatch("content/loadNews")
    await this.$store.dispatch("content/loadPriceList")
    await this.$store.dispatch("cart/loadDelivery")
    await this.$store.dispatch("catalog/loadCategories")
    // await this.$store.dispatch("catalog/loadAllGoods")
    await this.$store.dispatch("catalog/loadMerch")
    await this.$store.dispatch("catalog/loadPopular")
    await this.$store.dispatch("catalog/loadNew")
    await this.$store.dispatch("catalog/loadCert")
    await this.$store.dispatch('content/loadGallery')

    // загружаем все фильтры





    setTimeout(() => {
      this.preload = false
    }, 1000)
  }
}

</script>



<style>
.slide-leave-active {
  transition: opacity .3s ease;
  opacity: 0;
  animation: slide-out .3s ease-out forwards;
}

.slide-leave {
  opacity: 1;
  transform: translateX(0px);
}

.slide-enter-active {
  animation: slide-in .3s ease-out forwards;
  opacity: 1;
}



@keyframes slide-out {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(50px);
  }
}

@keyframes slide-in {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
</style>